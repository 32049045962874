<template>
    <el-container>
        <el-main>
            <div class="tabs">
                <el-tabs v-model="activeName" @tab-click="tabClick">
                    <el-tab-pane label="员工信息" name="first">
                        <el-table :data="staffList" style="width: 100%">
                            <el-table-column prop="p_u_id" label="ID" width="100"></el-table-column>
                            <el-table-column prop="u_account" label="登录账号"></el-table-column>
                            <el-table-column prop="u_name" label="用户名"></el-table-column>
                            <el-table-column prop="status" label="状态"></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick('editStaff', scope.row)" type="text"
                                        size="small">编辑</el-button>
                                    <el-button @click="handleClick('delStaff', scope.row)" type="text"
                                        size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <paging :total="staffListToal" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></paging>
                    </el-tab-pane>
                    <el-tab-pane label="角色管理" name="second">
                        <el-table :data="roleList" style="width: 100%">
                            <el-table-column prop="role_id" label="ID" width="70"></el-table-column>
                            <el-table-column prop="role_name" label="名称"></el-table-column>
                            <el-table-column prop="create_name" label="创建者"></el-table-column>
                            <el-table-column prop="case_name" label="产品方案"></el-table-column>
                            <el-table-column prop="create_time" label="创建时间" width="160"></el-table-column>
                            <el-table-column prop="update_time" label="最后更新时间" width="160"></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick('editRoles', scope.row)" type="text"
                                        size="small">编辑</el-button>
                                    <el-button @click="handleClick('delRoles', scope.row)" type="text"
                                        size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <paging :total="roleTotal" :page="page1" :pageNum="pageNum1" @updatePageNum="updateData"></paging>
                    </el-tab-pane>
                </el-tabs>
                <div class="addUser">
                    <el-button type="primary" v-if="tabIndex == 0" @click="handleClick('addStaff')" size="small"
                        icon="el-icon-plus">添加员工</el-button>
                    <el-button type="primary" v-else size="small" icon="el-icon-plus"
                        @click="handleClick('addRoles')">添加角色</el-button>
                    <router-link to="/shop/index">
                        <el-button size="small" icon="el-icon-arrow-left">返回</el-button>
                    </router-link>
                </div>
            </div>
        </el-main>
        <el-dialog title="添加员工" @close="closeCallback" :visible.sync="isStaffDialog" width="550px">
            <el-form label-width="60px">
                <el-form-item label="账号：">
                    <el-input v-model="staffForm.username"></el-input>
                </el-form-item>
                <el-form-item label="名称：">
                    <el-input v-model="staffForm.u_name"></el-input>
                </el-form-item>
                <el-form-item label="密码：">
                    <el-input type="password" v-model="staffForm.u_password"></el-input>
                </el-form-item>
                <el-form-item label="店铺：">
                    <el-select v-model="staffForm.account_id" @change="getShopInfo" :disabled="!handleStaffType" filterable
                        placeholder="请选择">
                        <el-option v-for="item in accountList" :key="item.uniacid" :label="item.name"
                            :value="item.uniacid"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="角色：">
                    <el-select v-model="staffForm.role_id" filterable placeholder="请选择">
                        <el-option v-for="item in selectRole" :key="item.role_id" :label="item.role_name"
                            :value="item.role_id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isStaffDialog = !1">取 消</el-button>
                <el-button type="primary" @click="addStaff">确 定</el-button>
            </span>
        </el-dialog>
        <el-drawer title="添加/编辑角色" ref="drawer" :visible.sync="drawer" size="700px" direction="rtl"
            custom-class="demo-drawer" :before-close="handleClose">
            <div class="demo-drawer__content">
                <el-form label-width="120px">
                    <el-form-item label="角色名称：">
                        <el-input v-model="rolesForm.role_name"></el-input>
                    </el-form-item>
                    <el-form-item label="产品方案：">
                        <el-select v-model="rolesForm.case_id" @change="getTreeList" filterable placeholder="请选择">
                            <el-option v-for="item in caseList" :key="item.id" :label="item.name"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态：">
                        <el-radio-group v-model="rolesForm.role_status">
                            <el-radio :label="1">正常</el-radio>
                            <el-radio :label="0">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="分配权限：">
                        <el-tree ref="tree" :data="treeList" :default-expanded-keys="defaultExpandedKeys" show-checkbox
                            node-key="auth_id" :props="defaultProps"></el-tree>
                    </el-form-item>
                </el-form>
                <div class="demo-drawer__footer">
                    <el-button @click="$refs.drawer.closeDrawer()">取 消</el-button>
                    <el-button type="primary" @click="treeSubmit">确认</el-button>
                </div>
            </div>
        </el-drawer>
    </el-container>
</template>

<script>
import paging from '@/components/paging';
import { getDateformat } from '../../util/getDate';
export default {
    data () {
        return {
            tabIndex: 0,
            drawer: !1,
            handleStaffType: 1,
            handelRolesType: 1,
            staffForm: {
                username: '',
                u_name: '',
                u_password: '',
                role_id: '',
                account_id: '',
            },
            rolesForm: {
                role_auth: '',
                role_status: 1,
                case_id: 1,
                role_name: '',
            },
            staffList: [],
            isStaffDialog: !1,
            page: 1,
            staffListToal: 0,
            pageNum: 5,
            roleTotal: 0,
            page1: 1,
            pageNum1: 5,
            roleList: [],
            activeName: 'first',
            selectRole: [],
            treeList: [],
            caseList: [],
            defaultExpandedKeys: [],
            defaultProps: {
                children: 'childs',
                label: 'auth_name',
            },
            accountList: [],
        };
    },
    components: {
        paging: paging,
    },
    watch: {
        drawer (val) {
            if (val && !this.treeList.length) this.getTreeList();
        },
        isStaffDialog (val) {
            if (val && !this.accountList.length) this.getAccountList();
        },
    },
    computed: {
        userInfo () {
            return this.$store.getters.userInfo;
        },
    },
    created () {
        this.getStaffInfo();
        this.getCase();
        this.$axios.get(this.$api.user.roles_getAll).then(res => {
            if (res.code == 0) {
                this.selectRole = res.result.list;
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    methods: {
        //获取方案
        getCase () {
            this.$axios.post(this.$api.site.ableCase).then(res => {
                if (res.code == 0) {
                    this.caseList = res.result;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getAccountList () {
            this.$axios
                .post(this.$api.site.accountList, {
                    pagesize: 1000,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.accountList = res.result.data;
                    }
                });
        },
        getShopInfo (val) {
            let shopInfo = this.accountList.find(item => item.uniacid == this.staffForm.account_id);
            if (shopInfo.meal.already_use_meal >= shopInfo.meal.maxsubaccount) {
                this.$message.error('员工账号数量已经超过您套餐赠送的数量，请联系管理员升级');
                if (!val) return false;
            } else {
                return true;
            }
        },
        //添加角色
        treeSubmit () {
            try {
                let rolesForm = this.rolesForm;
                let id = this.$refs.tree.getCheckedKeys();
                let treeList = this.treeList;
                for (let i in treeList) {
                    let childs = treeList[i].childs || [];
                    for (let y in childs) {
                        for (let k in id) {
                            if (id[k] == childs[y].auth_id) {
                                id.push(treeList[i].auth_id);
                            }
                        }
                    }
                }
                id = [...new Set(id)];
                id.sort(function (a, b) {
                    return a - b;
                });
                id = id.join(',');
                if (!rolesForm.role_name) throw '请填写角色名称';
                if (!rolesForm.case_id) throw '请选择产品方案';
                if (!id) throw '请选择分配权限';
                rolesForm.role_auth = id;
                let url = this.handelRolesType ? this.$api.auths.addRoles : this.$api.auths.editRoles;
                this.$axios.post(url, rolesForm).then(res => {
                    if (res.code == 0) {
                        this.getRoleInfo();
                        this.$axios.get(this.$api.user.roles_getAll).then(res => {
                            if (res.code == 0) {
                                this.selectRole = res.result.list;
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                        this.$refs.drawer.closeDrawer();
                        this.$message.success(this.handelRolesType ? '添加成功' : '编辑成功');
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            } catch (e) {
                this.$message.warning(e);
            }
        },
        //获取权限菜单
        getTreeList () {
            return new Promise(resolve => {
                this.$axios
                    .post(this.$api.auths.get_tree_list, {
                        case_id: this.rolesForm.case_id,
                    })
                    .then(res => {
                        if (res.code == 200) {
                            let list = res.result;
                            list.map(item => {
                                if (item.auth_id == 1) item.childs = [];
                            });
                            this.treeList = list;
                            resolve();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        tabClick ({ index }) {
            this.tabIndex = index;
            if (this.activeName == 'first' && !this.staffList.length) this.getStaffInfo();
            if (this.activeName == 'second' && !this.roleList.length) this.getRoleInfo();
        },
        closeCallback () {
            for (let i in this.staffForm) this.staffForm[i] = '';
        },
        updateData (val, status) {
            if (this.tabIndex == 0) {
                if (status == 0) {
                    this.pageNum = val;
                    this.getStaffInfo();
                } else {
                    this.page = val;
                    this.getStaffInfo();
                }
            } else {
                if (status == 0) {
                    this.pageNum1 = val;
                    this.getRoleInfo();
                } else {
                    this.page1 = val;
                    this.getRoleInfo();
                }
            }
        },
        async handleClick (type, row) {
            if (row) row = JSON.parse(JSON.stringify(row));
            switch (type) {
                case 'addStaff':
                    if (this.selectRole.length) {
                        this.isStaffDialog = !0;
                        this.handleStaffType = 1;
                    } else {
                        this.$message.warning('请先添加角色');
                    }
                    break;
                case 'editStaff':
                    this.staffForm.account_id = row.account_id;
                    this.staffForm.u_name = row.u_name;
                    this.staffForm.username = row.u_account;
                    this.staffForm.role_id = Number(row.role_id);
                    this.staffForm.u_id = row.u_id;
                    this.isStaffDialog = !0;
                    this.handleStaffType = 0;
                    break;
                case 'delStaff':
                    this.$confirm('确认是否删除此员工？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.$axios
                            .post(this.$api.user.users_del, {
                                id: row.u_id,
                            })
                            .then(res => {
                                if (res.code == 0) {
                                    this.staffList = this.staffList.filter(item => item.u_id != row.u_id);
                                    this.$message.success('删除成功');
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                    });
                    break;
                case 'delRoles':
                    this.$confirm('确认是否删除此角色？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.$axios.post(this.$api.user.roles_delete, { role_id: row.role_id }).then(res => {
                            if (res.code == 200) {
                                this.roleList = this.roleList.filter(item => item.role_id != row.role_id);
                                this.selectRole = this.selectRole.filter(item => item.role_id != row.role_id);
                                this.$message.success('删除成功');
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    });
                    break;
                case 'editRoles':
                    this.rolesForm.case_id = row.case_id;
                    await this.getTreeList().then(res => {
                        this.handelRolesInfo(row);
                    });
                    break;
                case 'addRoles':
                    this.treeList = [];
                    this.drawer = !0;
                    this.handelRolesType = 1;
                    this.$nextTick(() => {
                        this.$refs.tree.setCheckedKeys([]);
                    })
                    this.rolesForm = {
                        role_auth: '',
                        role_status: 1,
                        case_id: 1,
                        role_name: '',
                    };
                    break;
            }
        },
        handelRolesInfo (row) {
            let treeList = this.treeList;
            let tree_id = row.role_auth.split(',');
            let selectTree = [];
            this.rolesForm.role_name = row.role_name;
            this.defaultExpandedKeys = tree_id;
            for (let i in tree_id) tree_id[i] = Number(tree_id[i]);
            for (let i in treeList) {
                var childs = treeList[i].childs || [];
                for (let y in childs) {
                    tree_id.map(id => {
                        if (id == childs[y].auth_id) {
                            let obj = {
                                label: childs[y].auth_name,
                                auth_id: id,
                            };
                            selectTree.push(obj);
                        }
                    });
                }
            }
            this.drawer = !0;
            setTimeout(() => {
                this.$refs.tree.setCheckedNodes(selectTree);
                if (this.defaultExpandedKeys.find(id => id == 1)) {
                    this.$refs.tree.setChecked(1, true);
                }
            }, 50);
            this.handelRolesType = 0;
            this.rolesForm.role_status = row.role_status;
            this.rolesForm.role_id = row.role_id;
        },
        //获取角色列表
        getRoleInfo () {
            this.$axios
                .post(this.$api.user.roles_getAll, {
                    page: this.page1,
                    rows: this.pageNum1,
                })
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result.list;
                        list.map(i => {
                            i.create_time = getDateformat(i.create_time);
                            i.update_time = getDateformat(i.update_time);
                        });
                        this.roleList = list;
                        this.roleTotal = res.result.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //获取员工列表
        getStaffInfo () {
            this.$axios
                .post(this.$api.user.users_getAll, {
                    page: this.page,
                    rows: this.pageNum,
                })
                .then(res => {
                    if (res.code == 0) {
                        res.result.list.map(i => (i.u_status ? (i.status = '正常') : '禁用'));
                        this.staffListToal = res.result.total;
                        this.staffList = res.result.list;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //添加员工
        addStaff () {
            let staffForm = this.staffForm;
            let is_addAccount = true;
            if (!staffForm.username) return this.$message.error('请填写账号');
            if (!staffForm.u_name) return this.$message.error('请填写名称');
            if (this.handleStaffType) {
                if (!staffForm.u_password) return this.$message.error('请填写密码');
            }
            if (!staffForm.account_id) return this.$message.error('请选择店铺');
            if (!staffForm.role_id) return this.$message.error('请选择角色');
            let data = {
                u_name: staffForm.u_name,
                u_password: staffForm.u_password,
                role_id: staffForm.role_id,
                account_id: staffForm.account_id,
            };
            if (!this.handleStaffType) {
                data.u_id = staffForm.u_id;
                if (!data.u_password) delete data.u_password;
            } else {
                is_addAccount = this.getShopInfo();
            }
            this.handleStaffType ? (data.username = staffForm.username) : (data.u_account = staffForm.username);
            let url = this.handleStaffType ? this.$api.user.users_add : this.$api.user.users_update;
            if (is_addAccount) {
                this.$axios.post(url, data).then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: this.handleStaffType ? '添加成功' : '修改成功',
                            type: 'success',
                        });
                        this.page = 1;
                        this.getStaffInfo();
                        this.isStaffDialog = !1;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-container {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .el-main {
        width: 950px;
        background: #fff;

        .tabs {
            position: relative;

            .addUser {
                position: absolute;
                right: 0;
                top: 0;

                a {
                    margin-left: 10px;
                }
            }
        }
    }

    /deep/ .demo-drawer {
        overflow-y: scroll;
        padding-bottom: 50px;
    }

    .demo-drawer__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .el-form-item .el-input {
            width: 500px;
        }

        .demo-drawer__footer {
            padding: 5px 10px;
            width: 700px;
            position: fixed;
            border-top: 1px solid #ebf0f3;
            background: #fff;
            display: flex;
            z-index: 10;
            justify-content: flex-end;
            bottom: 0;
            right: 0;
        }
    }
}
</style>
